body.search-results .current_page_parent {
    color: $dark !important;
}

.search-results-list {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .results-list {
        display: grid;
        gap: 1.5rem;

        .search-result {
            display: grid;
            grid-template-columns: 10rem 1fr;
            align-items: start;
            gap: 1rem;
            padding-bottom: 1.5rem;
            border-bottom: 1px solid rgba($border, .2);

            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr;
            }

            .image {
                display: block;
                // height: 100%;

                .ar {

                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    background: $color;
                    gap: .5rem;
                    color: $white;
                    padding: 1rem;

                    .icon {
                        width: 6rem;
                    }

                    span {
                        font-size: 0.75rem;
                        text-transform: uppercase;
                        text-align: center;
                    }
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .content {
                // padding: .75rem 1.5rem;
                // background: $light;

                .category {
                    @extend .typo-l;
                    text-transform: uppercase;
                    margin-bottom: .25rem;
                }

                .title {
                    @extend .typo-s;
                    display: block;
                    text-decoration: none;
                }

                .summary {
                    font-size: 1rem;
                    margin: 1rem 0;

                    @include media-breakpoint-down(md) {
                        margin: .75rem 0;
                    }
                }

                .highlight {
                    font-size: .875rem;
                    margin: 0;
                    color: $turqalt;
                    opacity: .75;
                }

            }

            &.news {
                margin-bottom: 0;
            }
        }
    }

    .no-results {
        p {
            text-align: center;
            margin: 0;

            @include media-breakpoint-down(sm) {
                margin-bottom: 2rem;
            }
        }

        .search-form {
            display: flex;
            margin: 0;

            .form-control {
                border: 1px solid $dark;
                height: 3rem;
                font-size: 1rem;
                padding: .75rem 1rem;
            }

            .btn {
                border: 0;
                color: $white;
                background: $color;
                min-width: 3rem;
                transition: .25s;
                margin-left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 0 .25rem .25rem 0;

                i {
                    font-size: 1.25rem;
                    margin: 0;
                }

                &:hover {
                    color: $white;
                    background: $dark;
                }
            }
        }

    }

}