.cta {
	padding: 4rem 0;
	background-color: $turq;
	text-align: center;

	@include media-breakpoint-down(md) {
		padding: 3rem 0;
	}

	.image {
		img {
			width: 13rem;
		}
		margin-bottom: 2rem;
	}

	.title {
		@extend .typo-h;
		margin-bottom: 0.5rem;
	}

	.subtitle {
		@extend .typo-l;
		margin-bottom: 1rem;
	}

	.text {
		@extend .typo-r;
		color: $white;
		margin: 2rem 0;
	}

	.link {
		display: flex;
		gap: 1rem;
		justify-content: center;
	}
}
