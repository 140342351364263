.typo-h {
    font-size: 2rem;
    font-family: $font;
    line-height: 1;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
        font-size: 1.75rem;
    }

    @include media-breakpoint-down(md) {
        font-size: 1.5rem;
    }
}

.typo-s {
    font-size: 1.5rem;
    font-family: $font;
    line-height: 1;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
        font-size: 1.375rem;
    }
}

.typo-l {
    font-size: 1rem;
    font-family: $font;
    line-height: 1.375rem;
    margin: 0;
    text-transform: uppercase;
}

.typo-r {
    font-size: 1.375rem;
    line-height: 1.375;
    font-weight: normal;
    margin: 0;

    @include media-breakpoint-down(lg) {
        font-size: 1.125rem;
        line-height: 1.375;
    }

    p {
        margin-bottom: 1.375rem;

        @include media-breakpoint-down(lg) {
            margin-bottom: 1.125rem;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
        font-weight: bold;

        &:hover {
            color: $color;
        }
    }

    h2 {
        @extend .typo-h;
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }
    }

    h3 {
        @extend .typo-h;
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }
    }

    h4 {
        @extend .typo-h;
        margin-bottom: 1rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }
    }

    ul {
        list-style-type: none;
        padding-left: 0;
        margin-left: 1.75rem;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        li {
            margin-bottom: .5rem;
            line-height: 1.35;
            position: relative;
            // display: flex;

            &:before {
                position: absolute;
                flex: 0 0 auto;
                left: -1.75rem;
                top: 0;
                height: 1rem;
                width: 1rem;
                margin-top: .5rem;
                margin-right: .5rem;
                display: flex;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                font-size: 0.625rem;
                color: $white;
                content: "\f00c";
                // content: "";
                background: $turq;
                font-family: $font-icons;

                @include media-breakpoint-down(lg) {
                    // position: absolute;
                    // left: -1.5rem;
                    // top: .1285rem;
                    margin-top: .175rem;
                    height: 1rem;
                    width: 1rem;
                    font-size: .5rem;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

        }
    }

}

.typo-f {
    font-size: 1.5rem;
    font-family: $font;
    line-height: 1.375;
    font-weight: normal;
    margin: 0;
    text-transform: uppercase;
}