.homepage-header {

    .title {
        font-size: 2.5rem;

        @include media-breakpoint-down(lg) {
            font-size: 2rem;
        }

        @include media-breakpoint-down(md) {
            font-size: 1.75rem;
        }

        @include media-breakpoint-down(sm) {
            font-size: 1.625rem;
        }
    }

    .description {
        h2 {
            font-size: 1.625rem;
            text-transform: none;

            @include media-breakpoint-down(md) {
                font-size: 1.5rem;
            }
    
            @include media-breakpoint-down(sm) {
                font-size: 1.375rem;
            }
        }
    }

}