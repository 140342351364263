.block-blocks {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .block {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include media-breakpoint-down(lg) {
            display: grid;
            grid-template-columns: 40% 60%;
        }

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .content {
            display: flex;
            flex-direction: column;
            width: 75%;
            max-width: 40vw;
            margin: 0 auto;
            padding: 0 2rem;

            @include media-breakpoint-down(xxl) {
                width: 80%;
                max-width: 50vw;
                padding: 0 1rem;
            }


            @include media-breakpoint-down(lg) {
                width: 100%;
                max-width: 100%;
                padding: 0 1rem;
            }

            @include media-breakpoint-down(md) {
                padding: 0;
            }

            .title {
                @extend .typo-h;
                margin: 0 0 1.5rem;

                @include media-breakpoint-down(md) {
                    margin: 0 0 1rem;
                }
            }

            .text {
                @extend .typo-r;
            }

            .link {
                margin-top: 2rem;

                @include media-breakpoint-down(md) {
                    margin-top: 1rem;
                }
            }
        }

        .media {
            z-index: 1;

            .image-wrapper {
                display: block;
                overflow: hidden;

                picture,
                img {
                    max-width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

        }
    }

    &.full-width {
        .block {}
    }

    &.bg-light {
        background: $light;
    }
}