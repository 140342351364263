.view-configurator {
    .renders {
        border: 1px solid rgba($border, .2);
    }

    .configurator {

        margin-top: 2rem;

        h5 {
            @extend .typo-r;
            text-transform: uppercase;
            margin-bottom: .5rem;
        }

        .accordion-finishes {
            text-transform: uppercase;
            font-size: .875rem;

            .accordion-finish {
                // border: 0;
                border-right: 0;
                border-left: 0;

                .accordion-trigger {
                    display: flex;
                    align-items: center;
                    padding: .5rem 2rem .5rem .5rem;
                    position: relative;
                    gap: .5rem;

                    &::after {
                        content: "\f107";
                        position: absolute;
                        right: .5rem;
                        font-family: $font-icons;
                        font-size: 2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .3s ease-in-out;
                    }

                    &[aria-expanded=true] {

                        &::after {
                            transform: scale(-1);
                        }
                    }

                    .finish-color {
                        width: 2rem;
                        height: 2rem;
                        border-radius: 50%;
                    }
                }

                .accordion-body {
                    padding: .5rem;
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    background: $light;
                    border-top: 1px solid rgba($border, .2);

                    .custom-list {
                        margin-bottom: 1rem;
                    }
                    
                    .set-title {
                        margin: .5rem 0;
                    }

                    .swatch {
                        display: flex;
                        gap: .5rem;
                        align-items: center;
                        cursor: pointer;

                        .color {
                            width: 2rem;
                            height: 2rem;
                            border-radius: 50%;
                            overflow: hidden;
                            border: 2px solid $light;
                        }

                        &.active {
                            .color {
                                border-color: $dark;
                            }
                        }
                    }
                }
            }
        }
    }
}