// Font Awesome
$fa-font-path: "../fonts/fontawesome6" !default;

// Options
$enable-caret: false !default;
$enable-rounded: false !default;
$enable-shadows: false !default;

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px,
) !default;

// Grid containers
$container-max-widths: (
    sm: 100%,
    md: 100%,
    lg: 100%,
    xl: 1140px,
    xxl: 1540px,
) !default;

// Grid options
$grid-gutter-width: 2rem !default;

// Typography
$headings-font-weight: bold !default;

// Components
$border-radius: 0 !default;

// Buttons + Forms
$input-btn-focus-box-shadow: 0 !default;
$btn-focus-box-shadow: 0 !default;

// Forms
$form-check-input-checked-bg-color: #12253a;
$form-check-input-focus-border: 1px solid rgba(0,0,0, 1);