.btn {
    font-size: 1rem;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s ease-in-out;
    font-family: $font;
    padding: 0.75rem 3rem;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 0.375rem;
    background: $color;
    color: $white;
    text-align: center;
    border: 0;
    cursor: pointer;

    @include media-breakpoint-down(md) {
        font-size: 0.875rem;
    }

    &:hover {
        background: $turq;
        color: $white;
    }

	&.transparent {
		background: transparent;
		border: 1px solid $white;

		&:hover {
			border-color: $turq;
		}
	}

    &.white {
        background: $white;
        color: $dark;

        &:hover {
            background: $color;
            color: $white;
        }

		&.transparent {
			border-color: $dark;
	
			&:hover {
				border-color: $color;
			}
		}
    }

    &.dark {
        background: $dark;
        color: $white;

        &:hover {
            background: $turq;
            color: $white;
        }

		&.transparent {
			border-color: $white;
	
			&:hover {
				border-color: $turq;
			}
		}
	}

    &.squared {
        border-radius: 0;
    }

    &.icon {
        position: relative;

        i {
            font-size: 1rem;
            position: absolute;
            left: 0;
            width: 3rem;
            transition: 0.25s ease-in-out;
            text-align: center;
        }

        &:hover {
            i {
                color: inherit;
            }
        }
    }
}

// .btn-text {
//     font-size: 1.0625rem;
//     // letter-spacing: 0.05em;
//     font-weight: bold;
//     line-height: 1;
//     display: inline;
//     align-items: center;
//     transition: .15s ease-in-out;
//     // padding: 0 0 .875rem;
//     text-transform: uppercase;
//     text-decoration: none;

//     @include media-breakpoint-down(md) {
//     font-size: 1rem;
//     //     // padding: .875rem;
//     }

//     i {
//         margin-left: .5rem;
//         transition: .3s;
//     }

//     &:hover {

//         i {
//             margin-left: 1rem;

//         }
//     }
// }