.quicklinks {
	background: $white;
	border-top: 1px solid rgba($border, 0.2);

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	.quicklink {
		border-left: 1px solid rgba($border, 0.2);
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2rem 1rem;
		text-decoration: none;
		text-align: center;

		&:first-child {
			border-left: 0;
		}

		@include media-breakpoint-down(lg) {
			padding: 1rem 1rem;
		}

		@include media-breakpoint-down(md) {
			border-left: 0;
		}

		.icon {
			font-size: 2.5rem;
			margin-bottom: 1rem;
			transition: 0.25s ease-in-out;
		}

		.title {
			@extend .typo-l;
			line-height: 1;
			display: block;
			margin-bottom: 1rem;
		}

		.text {
			@extend .typo-r;
			margin-bottom: 0.5rem;
			font-weight: bold;
		}

		.label {
			font-size: 0.875rem;
			transition: 0.25s ease-in-out;
		}

		&:hover {
			.icon,
			.label {
				color: $turq;
			}
		}
	}
}

.footer {
	background: $color;
	color: $white;
	position: relative;

	.social-links {
		padding: 3rem 0;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 2rem;

		@include media-breakpoint-down(md) {
			padding: 2rem 0;
		}

		a {
			color: $white;
			text-decoration: none;
			font-size: 2rem;
			line-height: 1;
			margin-left: 0.75rem;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				color: $turq;
			}
		}
	}

	.infos {
		font-size: 0.875rem;

		.footer-logo {
			text-align: left;
			margin-bottom: 3rem;

			@include media-breakpoint-down(md) {
				text-align: center;
			}

			img {
				min-height: 3rem;
				max-height: 3rem;
			}

			@include media-breakpoint-down(md) {
				text-align: center;
				margin-bottom: 2rem;
			}
		}

		.company {
			text-align: center;
			margin-bottom: 3rem;

			span {
				display: block;
			}

			a {
				text-decoration: none;
			}

			@include media-breakpoint-down(md) {
				text-align: center;
				margin-bottom: 2rem;
			}
		}

		.privacy {
			display: flex;
			flex-direction: column;
			text-transform: uppercase;
			text-align: right;
			margin-bottom: 3rem;

			span {
				display: block;
			}

			a {
				text-decoration: none;
			}

			@include media-breakpoint-down(md) {
				text-align: center;
				margin-bottom: 2rem;
			}
		}
	}

	.credits {
		font-size: 0.875rem;
		display: block;
		text-align: center;
		padding: 0 0 3rem;
        text-decoration: none;
	}
}
