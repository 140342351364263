.block-cta {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .wrapper {
        background-color: $turq;
        position: relative;
        padding: 3rem;
        border-radius: 1rem;
        color: $white;

        @include media-breakpoint-down(lg) {
            padding: 2rem;
        }

        @include media-breakpoint-down(md) {
            padding: 1rem;
        }

        .content {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }

            .title {
                @extend .typo-h;
                margin: 0;
                color: $white;
                margin-bottom: 1rem;
            }

            .text {
                @extend .typo-r;
                margin-bottom: 2rem;

            }

            .link {
                margin-top: auto;
            }
        }

        .icon {
            container-type: inline-size;

            i {
                font-size: 100cqw;
            }
        }
    }
}