.block-contact {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .intro {
        padding-bottom: 4rem;
        text-align: center;

        @include media-breakpoint-down(lg) {
            padding-bottom: 3rem;
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 2rem;
        }

        .title {
            @extend .typo-h;
            margin: 0 0 1rem;
            color: $color;
            text-transform: uppercase;
        }

        .text {
            @extend .typo-r;
        }
    }

    .hubspot-form {
        margin-top: 1rem;

        form {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;

            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr;
            }

            .hs-dependent-field,
            .legal-consent-container {
                grid-column: span 2;
                display: grid;
                gap: 1rem;

                @include media-breakpoint-down(md) {
                    grid-column: span 1;
                }
            }

            // Hubspot overrides
            .hs-fieldtype-text,
            .hs-fieldtype-textarea,
            .hs-fieldtype-select,
            .hs-fieldtype-checkbox,
            .hs-fieldtype-phonenumber {
                width: 100%;
            }

            .hs-fieldtype-text,
            .hs-fieldtype-phonenumber,
            .hs-fieldtype-file {
                &>label {
                    // display: none;
                    margin-bottom: .5rem;
                }

                .input {
                    input {
                        @extend .form-control;
                        border-color: rgba($border, 1);
                        border-radius: 0;
                    }

                    label {
                        display: block;
                    }
                }
            }

            .hs-fieldtype-checkbox {

                &.hs-categorie_prodotto {
                    grid-column: span 2;

                    @include media-breakpoint-down(md) {
                        grid-column: span 1;
                    }
                }

                &>label {
                    // display: none;
                    margin-bottom: .5rem;
                }

                .input {
                    .inputs-list {
                        display: flex;
                        list-style: none;
                        flex-wrap: wrap;
                        padding: 0;
                        margin: 0;
                        gap: 1rem;

                        .hs-form-checkbox-display {
                            @extend .form-check;

                            input {
                                @extend .form-check-input;
                                margin-top: 0.125em;
                                border-color: rgba($border, 1);
                            }

                            span {
                                @extend .form-check-label;
                            }
                        }
                    }
                }
            }

            .hs-fieldtype-textarea {
                grid-column: span 2;

                @include media-breakpoint-down(md) {
                    grid-column: span 1;
                }

                &>label {
                    // display: none;
                    margin-bottom: .5rem;
                }

                .input {
                    textarea {
                        @extend .form-control;
                        height: auto;
                        border-color: rgba($border, 1);
                        border-radius: 0;
                        min-height: 6rem;
                    }

                    label {
                        display: block;
                    }
                }
            }

            .hs-fieldtype-select {
                &>label {
                    // display: none;
                    margin-bottom: .5rem;
                }

                .input {
                    select {
                        @extend .form-select;
                        border-color: rgba($border, 1);
                        border-radius: 0;
                        width: 100%;
                    }

                    label {
                        display: block;
                    }
                }
            }

            .hs-fieldtype-booleancheckbox {
                grid-column: span 2;

                @include media-breakpoint-down(md) {
                    grid-column: span 1;
                }

                .inputs-list {
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    .hs-form-booleancheckbox-display {
                        @extend .form-check;

                        input {
                            @extend .form-check-input;
                            margin-top: 0;
                            border-color: rgba($border, 1);
                        }

                        span {
                            font-size: .875rem;
                            @extend .form-check-label;
                            margin-left: 0;
                        }
                    }
                }

                .hs-error-msgs {
                    margin-left: 1.5rem;
                }
            }

            .hs-error-msgs {
                list-style: none;
                font-size: .75rem;
                margin: .25rem 0 0;
                padding: 0;
                color: red;
            }

            a {
                color: $color;
                font-weight: bold;
            }

            .hs-submit {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                grid-column: span 2;

                @include media-breakpoint-down(md) {
                    grid-column: span 1;
                }


                input {
                    @extend .btn;
                    border: 0;
                }
            }

            // Custom overrides
            .span2 {
                grid-column: span 2;

                @include media-breakpoint-down(md) {
                    grid-column: span 1;
                }
            }

            .form-group {

                .form-control {
                    border-color: rgba($border, 1);
                }

                label {
                    margin-bottom: .5rem;
                }
            }

            .form-check-input {
                border-color: rgba($border, 1);
            }

            .form-check-label {
                font-size: .875rem;
            }
        }

    }

    &.bg-light {
        background: $light;
    }
}

.grecaptcha-badge {
    display: none !important;
}