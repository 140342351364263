.page-grid {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }
}

.page-tease {
    text-decoration: none;

    .wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        .image-box {
            position: relative;
            background: rgba($color, .5);

            .icon {
                background: $turq;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                &:before {
                    content: "";
                    padding-top: 75%;
                }

                i {
                    font-size: 5rem;
                    color: $white;
                }
            }

            .image {
                display: block;
                transition: .25s ease-in-out;

                picture,
                img {
                    width: 100%;
                    object-fit: contain;
                }
            }

            .svg {
                background-color: $turq;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                img {
                    width: 5rem;
                    filter: invert(1);
                }
            }
        }

        .content {
            position: absolute;
            bottom: 0;
            display: flex;
            flex-direction: column;
            padding: 1rem 2rem;
            gap: .25rem;

            .title {
                @extend .typo-r;
                color: $white;
                text-decoration: none;
                display: block;
                text-transform: uppercase;
                font-weight: bold;
                text-shadow: 0 0 .125rem rgba($dark, 1);
            }

            .meta {
                display: flex;
                align-items: center;
                gap: 1rem;

                .category {
                    background: $turq;
                    color: $white;
                    padding: 0.25rem 0.5rem;
                    display: inline-block;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-size: 0.75rem;

                    &:hover {
                        background: $turqalt;
                        color: $white;
                    }
                }

                .date {
                    padding: 0.25rem 0.5rem;
                    font-size: 0.75rem;
                    color: $white;
                    text-transform: uppercase;
                    margin-top: auto;
                    text-shadow: 0 0 .125rem rgba($dark, 1);
                }

                .extra {
                    font-size: 0.75rem;
                    color: $white;
                    text-transform: uppercase;
                    margin-top: auto;
                }
            }
        }
    }

    &:hover {
        .image-box {

            .image {
                // mix-blend-mode: overlay;
                // filter: grayscale(1);
            }
        }
    }
}