.block-gallery {
    overflow: hidden;
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .intro {
        padding-bottom: 4rem;
        text-align: center;

        @include media-breakpoint-down(lg) {
            padding-bottom: 3rem;
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 2rem;
        }

        .title {
            @extend .typo-h;
            margin: 0 0 1rem;
            color: $color;
            text-transform: uppercase;
        }

        .text {
            @extend .typo-r;
        }

    }

    .gallery-masonry {
        display: flex;
        gap: 1rem;

        .masonry-col {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @include media-breakpoint-down(md) {
                width: 100%;

                &:last-child {
                    display: none;
                }
            }

            .masonry-tile {
                // margin-bottom: 1rem;

                .caption {
                    margin-top: .5rem;
                }
            }
        }
    }

    .gallery-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(4, 1fr);
        }

        .grid-cell {
            display: flex;
            justify-content: center;
            align-items: center;

            .caption {
                margin-top: .5rem;
            }
        }
    }

    .gallery-slider {
        overflow: visible;
        position: relative;

        .slide {
            width: auto;

            img {
                height: 45vh;
                width: auto;
            }

        }

        .gallery-nav {
            position: absolute;
            top: 50%;
            background: $white;
            z-index: 2;
            margin-top: -1rem;
            font-size: 1.75rem;
            cursor: pointer;
            transition: .3s;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: .75;

            &:hover {
                opacity: 1;
            }

            &.prev {
                left: .5rem;
            }

            &.next {
                right: .5rem;
            }
        }
    }

    &.bg-light {
        background: $light;
    }

    &.bg-color {
        background: $color;

        .intro,
        .title,
        .text,
        .caption {
            color: $white;
        }
    }
}