@font-face {
  font-family: "Azzurra Sans";
  font-weight: normal;
  // src: url(../fonts/azzurra/17fe4ab4-6e45-4ea2-bb53-2123063911c3.eot?#iefix) format("eot"), url(../fonts/azzurra/46ce09db-0ca6-4e0c-acea-3ee8c8867f10.woff2) format("woff2"), url(../fonts/azzurra/142b09e6-c245-4ff7-b01c-325fa824c99e.woff) format("woff"), url(../fonts/azzurra/e4509cd4-7aa0-4068-89bd-95ffd0bfb6d4.ttf) format("truetype"); 
  src: url(../fonts/azzurra/46ce09db-0ca6-4e0c-acea-3ee8c8867f10.woff2) format("woff2"); 
  font-display: swap;
}

@font-face {
  font-family: "Azzurra Sans";
  font-weight: normal;
  font-style: italic;
  // src: url(../fonts/azzurra/2e6cfa9e-fbb1-41ae-865f-1a1b7e472cdc.eot?#iefix) format("eot"), url(../fonts/azzurra/f940347c-b756-4b0c-b9b7-980420355ae3.woff2) format("woff2"), url(../fonts/azzurra/34962837-192c-48f0-bd43-5bf8f325f09f.woff) format("woff"), url(../fonts/azzurra/4fdf6f8e-9073-43e6-a953-6bb368860c9e.ttf) format("truetype"); 
  src: url(../fonts/azzurra/f940347c-b756-4b0c-b9b7-980420355ae3.woff2) format("woff2"); 
  font-display: swap;
}

@font-face {
  font-family: "Azzurra Sans";
  font-weight: bold;
  // src: url(../fonts/azzurra/7ed1bebb-81a9-4c57-9513-f0538c1b83fc.eot?#iefix) format("eot"), url(../fonts/azzurra/9fb69c26-4303-492d-8121-d3af0a6112fc.woff2) format("woff2"), url(../fonts/azzurra/ddc0f223-a296-451a-8681-ad80508eb419.woff) format("woff"), url(../fonts/azzurra/c18fc865-7a4e-45d9-bbb0-4e4553cc4136.ttf) format("truetype"); 
  src: url(../fonts/azzurra/9fb69c26-4303-492d-8121-d3af0a6112fc.woff2) format("woff2"); 
  font-display: swap;
}

@font-face {
  font-family: "Azzurra Sans";
  font-weight: bold;
  font-style: italic;
  // src: url(../fonts/azzurra/80aeba20-efa4-4aea-9dff-fe3d6e9b25db.eot?#iefix) format("eot"), url(../fonts/azzurra/c3e9faf3-8e1c-40d6-b445-4f55b7152a84.woff2) format("woff2"), url(../fonts/azzurra/b25a4ccb-ae7a-4b19-85fc-f4ca88c5cd26.woff) format("woff"), url(../fonts/azzurra/9ef2e85e-4a0e-4825-8f02-a2b708f8c6e7.ttf) format("truetype"); 
  src: url(../fonts/azzurra/c3e9faf3-8e1c-40d6-b445-4f55b7152a84.woff2) format("woff2"); 
  font-display: swap;
}