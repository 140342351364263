.block-finish {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .intro {
        padding-bottom: 4rem;

        @include media-breakpoint-down(lg) {
            padding-bottom: 3rem;
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 2rem;
        }

        .title {
            @extend .typo-h;
            margin: 0 0 1rem;
            color: $color;
            text-transform: uppercase;
        }

        .text {
            @extend .typo-r;
            margin: 0 0 1rem;
        }

        .collections {
            @extend .typo-r;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: .5rem;

            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(2, 1fr);
            }

            a {
                font-weight: normal;
            }
        }

    }

    &.bg-light {
        background: $light;
    }

    .finish {


        .swatches {
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(4, 1fr);

            .color {
                margin-bottom: .25rem;
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(3, 1fr);
            }

            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}