.menu-desktop {
    flex: 1;
    padding: 0;
    // position: relative;
    justify-content: center;
    gap: 1rem;
    align-items: center;

    @include media-breakpoint-up(xxl) {
        justify-content: center;
        gap: 2rem;
    }

    .nav-item {
        color: $dark;
        transition: 0.15s ease-in-out;
        text-align: center;

        &.visible,
        &.active {
            color: $turq;

            .nav-link {
                &::after {
                    transform: scaleY(1);
                }
            }
        }

        .nav-link {
            display: flex;
            position: relative;
            color: inherit;
            align-items: center;
            line-height: 1rem;
            padding: 1.375rem 0;
            font-size: 0.875rem;
            text-transform: uppercase;
            font-weight: bold;
            transition: 0.15s ease-in-out;

            @include media-breakpoint-down(xl) {
                font-size: 0.75rem;
            }

            @include media-breakpoint-down(lg) {
                font-size: 0.875rem;
            }

            &::after {
                display: block;
                position: absolute;
                bottom: 0;
                content: "";
                height: 0.25rem;
                width: 100%;
                background: $turq;
                transform: scaleY(0);
                transform-origin: 0 100%;
                transition: 0.15s ease-in-out;
            }
        }

        // Dropdown
        &.dropdown {
            position: relative;

            &.active {
                color: $color;
            }

            .dropdown-menu {
                display: flex; //debug
                display: none;
                position: absolute;
                top: 100%;
                left: 0;
                background-clip: padding-box;
                background-color: $white;
                border: 1px solid rgba($border, 0.2);
                float: left;
                list-style: none;
                margin: 0.0625rem 0 0;
                min-width: 16rem;
                padding: 0 1rem;
                text-align: left;
                z-index: 1000;
                border-top: 0;
                flex-direction: column;

                .dropdown-item {
                    display: inline-flex;
                    align-items: center;
                    white-space: nowrap;
                    padding: 1rem 0;
                    font-size: .875rem;
                    line-height: .875rem;
                    color: $dark;
                    background: none;
                    display: inline-flex;
                    align-items: center;
                    text-transform: uppercase;
                    border-bottom: 1px solid rgba($border, 0.2);

                    &:last-child {
                        border-bottom: 0;
                    }

                    &::before {
                        content: "";
                        height: .625rem;
                        width: .625rem;
                        background: $turq;
                        display: block;
                        margin-right: 0.25rem;
                    }

                    &:hover,
                    &.active {
                        color: inherit;
                        background: inherit;

                        &::before {
                            background: $color;
                        }
                    }
                }
            }

            // &:last-child {
            //     .dropdown-menu {
            //         right: 0;
            //         left: auto;
            //     }
            // }

            &.visible {
                .dropdown-menu {
                    display: flex;
                }
            }

            // Megamenu
            &.megamenu {
                position: static;

                .mega {
                    left: -1rem;
                    min-width: 100vw;
                    padding: 0;
                    border: 0;
                    border: 1px solid rgba($border, 0.2);
                    border-top: 0;
                    margin-top: 1px;
                    background: none;
                    // display: flex;
                }

                .mega-content {
                    display: flex;
                    position: relative;
                    background: none;
                    padding: 0;
                    max-width: 75vw;
                    min-width: 75vw;
                    min-height: 20rem;
                    flex-direction: row;
                    margin: 0 auto;
                    border: 0;

                    @include media-breakpoint-down(xl) {
                        max-width: 80vw;
                        min-width: 80vw;
                    }

                    @include media-breakpoint-down(lg) {
                        max-width: 90vw;
                        min-width: 90vw;
                    }

                    &:before {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "";
                        height: 100%;
                        width: 22.5vw;
                        background: rgba($light, 0.95);
						transform: scalex(-1);
						transform-origin: 0;

                        @include media-breakpoint-down(xl) {
                            width: 20vw;
                        }

                        @include media-breakpoint-down(lg) {
                            width: 10vw;
                        }
                    }

                    &:after {
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        content: "";
                        height: 100%;
                        width: 22.5vw;
                        background: rgba($white, 0.95);
						transform: scalex(-1);
						transform-origin: 100%;

                        @include media-breakpoint-down(xl) {
                            width: 20vw;
                        }

                        @include media-breakpoint-down(lg) {
                            width: 10vw;
                        }
                    }

                    .wrapper {
                        display: flex;
                        width: 100%;
						padding: 0;

                        .mega-left {
                            position: relative;
                            width: 30%;
                            padding: 2rem 0;
                            background: rgba($light, 0.95);
                            display: flex;
                            flex-direction: column;

                            // @include media-breakpoint-down(xl) {
                            //     width: 30%;
                            // }

                            .triggers {

                                .trigger {
                                    display: block;
                                    position: relative;
                                    padding: 1.5rem 2rem;
                                    font-size: 1rem;
                                    color: $text;
                                    text-decoration: none;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                    z-index: 1;

                                    &::before {
                                        content: "";
                                        width: 50vw;
                                        height: 100%;
                                        background: rgba($white, 0.9);
                                        position: absolute;
                                        right: 0;
                                        top: 0;
                                        opacity: 0;
                                        transition: all 0.25s;
                                        z-index: -1;
                                    }

                                    &:hover {
                                        &::before {
                                            opacity: 1;
                                        }
                                    }

                                    &[aria-selected="true"] {
                                        color: $turq;

                                        &::before {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }

                            .info {
                                margin-top: auto;

                                .box {
                                    display: flex;
                                    width: 100%;
                                    height: 100%;
                                    flex-direction: column;
                                    cursor: pointer;
                                    transition: all 0.25s;
                                    padding: 2rem 2rem 0;
                                }

                                .text {
                                    @extend .typo-l;
                                    text-transform: none;
                                }

                                .link {
                                    margin-top: 1rem;
                                    display: block;
                                    font-size: 1.25rem;
                                    text-transform: uppercase;
                                    font-weight: bold;
                                    color: $turq;
                                    text-decoration: none;

                                    &:hover {
                                        color: $color;
                                    }
                                }
                            }
                        }

                        .mega-right {
                            position: relative;
                            width: 70%;
                            padding: 2rem 0;
                            background: rgba($white, 0.95);

                            // @include media-breakpoint-down(xl) {
                            //     width: 70%;
                            // }

                            .contents {
                                height: 100%;
                                padding: 0 2rem;

                                .content {
									column-gap: 2rem;
									columns: 3;

									// @include media-breakpoint-up(xxl) {
									// 	columns: 3;
									// }

                                    .sub-link {
                                        display: flex;
                                        align-items: center;
                                        white-space: wrap;
                                        padding: .5rem 0;
                                        font-size: 0.875rem;
                                        line-height: .875rem;
                                        color: $dark;
                                        background: none;
                                        text-transform: uppercase;
                                        text-decoration: none;

                                        &::before {
                                            content: "";
                                            height: 0.625rem;
                                            width: 0.625rem;
                                            background: $turq;
                                            display: block;
                                            margin-right: 0.25rem;
                                        }

                                        &:hover,
                                        &.active {
                                            color: inherit;
                                            background: inherit;

                                            &::before {
                                                background: $color;
                                            }
                                        }
                                    }

                                    &[aria-hidden="true"] {
                                        display: none;
                                    }

                                    .group {
                                        display: block;
                                        height: auto;
                                        margin-bottom: 1rem;
                                        page-break-inside: avoid;
                                        break-inside: avoid-column;

                                        .head {
                                            display: block;
                                            font-size: 1rem;
                                            text-transform: uppercase;
                                            font-weight: bold;
                                            border-bottom: 1px solid rgba($border, 0.2);
                                            margin-bottom: 0.5rem;
                                            padding-bottom: .5rem;
                                            text-decoration: none;

                                            &:hover,
                                            &.active {
                                                color: $color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}