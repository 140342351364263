// Header
.collection-header {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .content {
        text-align: left;
        z-index: 1;

        .title {
            color: $color;
            margin-bottom: .5rem;
            @extend .typo-h;
        }

        .subtitle {
            @extend .typo-l;
        }

        .meta {
            display: flex;
            margin: 1rem 0 2rem;
            flex-direction: column;
            gap: .5rem;
        }

        .intro {
            @extend .typo-r;
        }
    }

    .media {

        @include media-breakpoint-down(md) {
            margin-bottom: 2rem;
        }
    }
}

// Descrizione
.collection-description {
    padding: 4rem 0;
    background-color: var(--bg);

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    &.contrast {
        color: $white !important;
    }

    .description {
        text-align: center;
        @extend .typo-r;
    }

}

// Media
.collection-media {
    // max-width: 1920px;
    // margin: 0 auto;
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .masonry {
        display: flex;
        gap: 1rem;

        .masonry-col {
            display: flex;
            width: 50%;
            flex-direction: column;
            gap: 1rem;

            @include media-breakpoint-down(md) {
                width: 100%;

                &:last-child {
                    display: none;
                }
            }
        }

        .masonry-tile {
            // margin-bottom: 1rem;

            .group-media {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            .collection-gallery {
                position: relative;
                overflow: hidden;

                .collection-slide {
                    height: auto;

                    picture,
                    img {
                        // height: 100%;
                        object-fit: cover;
                    }
                }

                .collection-nav {
                    display: flex;
                    position: absolute;
                    top: 50%;
                    background: $light;
                    z-index: 2;
                    margin-top: -1rem;
                    font-size: 1rem;
                    cursor: pointer;
                    transition: .3s;
                    height: 2rem;
                    width: 2rem;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    opacity: .75;

                    &:hover {
                        opacity: 1;
                    }

                    &.prev {
                        left: .5rem;
                    }

                    &.next {
                        right: .5rem;
                    }
                }
            }
        }
    }
}

// Focus Designer
.collection-designer {
    padding: 4rem 0;
    background-color: var(--bg);

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    &.contrast {
        color: $white !important;
    }

    .content {

        .title {
            margin-bottom: 2rem;

            h3 {
                font-weight: normal;
                @extend .typo-h;
            }
        }

        .description {
            @extend .typo-r;
        }

        .link {
            margin-top: 2rem;

            .btn {
                color: currentColor;
                border-color: currentColor;

                &:hover {
                    border-color: $turq;
                    background: $turq;
                    color: $white;
                }

            }
        }
    }
}