.block-video {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .intro {
        padding-bottom: 4rem;
        text-align: center;

        @include media-breakpoint-down(lg) {
            padding-bottom: 3rem;
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 2rem;
        }

        .title {
            @extend .typo-h;
            margin: 0 0 1rem;
            color: $color;
            text-transform: uppercase;
        }

        .text {
            @extend .typo-r;
        }

    }

    .video {
        video {
            background: $light;
            position: relative;

            &[poster] {
                height: 100%;
                width: 100%;
            }
        }
    }
}