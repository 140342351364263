.page-tech {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .accordion-item-main {

        border: 0;
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }

        .wrapper {
            .trigger {
                background: $color;

                .title {
                    padding: .5rem 2rem;
                    color: $white;
                    display: flex;
                    align-items: center;
                    -webkit-appearance: none;

                    h4 {
                        @extend .typo-h;
                        font-size: 1.5rem;
                        font-weight: bold;
                        margin: 0;
                        text-transform: uppercase;
                    }

                    i {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.75rem;
                        line-height: 1.25;
                        margin-left: auto;
                        transition: .15s ease-in-out;
                    }

                    &:hover {
                        // background: $dark;
                        color: $white;
                    }

                    &:not(.collapsed) {
                        // background: $dark;
                        color: $white;

                        i {
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            .content {
                padding: 1.5rem;
                border: 1px solid rgba($border, .2);

                .text {
                    @extend .typo-r;
                }
            }
        }

    }

    .accordion-item-sub {
        border: 0;
        margin-bottom: 1px;

        &:last-child {
            margin-bottom: 0;
        }

        .wrapper {
            .trigger {
                background: $turq;

                .title {
                    padding: .5rem 1rem;
                    color: $white;
                    display: flex;
                    align-items: center;
                    -webkit-appearance: none;

                    h5 {
                        @extend .typo-h;
                        font-size: 1.125rem;
                        font-weight: bold;
                        margin: 0;
                        text-transform: uppercase;
                    }

                    i {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.125rem;
                        line-height: 1.25;
                        margin-left: auto;
                        transition: .15s ease-in-out;
                    }

                    &:hover {
                        // background: $dark;
                        color: $white;
                    }

                    &:not(.collapsed) {
                        // background: $dark;
                        color: $white;

                        i {
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            .content {
                border: 0;
                padding: 1rem 0;
            }
        }

    }

    .tech-grid {
        display: grid;
        grid-template-columns: 1fr;

        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
        }

        .tech-item {
            display: grid;
            grid-template-columns: 30% 15% 14% minmax(15%, auto) minmax(13%, auto) minmax(13%, auto);
            gap: .5rem 1rem;
            border-bottom: 1px solid rgba($border, .2);
            font-size: .875rem;
            padding: .75rem 1rem;

            .name {
                strong {
                    text-transform: uppercase;
                }
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(3, 1fr);
                gap: 1rem;

                .name {
                    // grid-column: span 2;
                }
            }

            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(1, 1fr);
                gap: .5rem 1rem;
                border: 1px solid rgba($border, .2);
                border: 1px solid rgba($border, .2);

                .name {
                    grid-column: span 1;
                }

                .size {
                    margin-bottom: 1rem;
                }
            }

            a {
                text-decoration: none;
                text-transform: uppercase;
            }
        }
    }

}