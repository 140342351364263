.post-header {
    padding: 4rem 0;
    text-align: center;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
        text-align: left;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .title {
        margin: 0 0 1.5rem;

        @include media-breakpoint-down(md) {
            margin: 0 0 1rem;
        }

        @extend .typo-h;
        margin-top: 0;
        color: $color;
        text-transform: uppercase;
    }

    .description {
        @extend .typo-r;
    }

    .details {
        margin-top: 2rem;

        @include media-breakpoint-down(md) {
            margin-top: 1rem;
        }

        .meta {
            display: flex;
            align-items: center;

            .category {
                background: $turq;
                color: $white;
                padding: 0.25rem 0.5rem;
                display: inline-block;
                text-transform: uppercase;
                text-decoration: none;
                font-size: 0.75rem;

                &:hover {
                    background: $turqalt;
                    color: $white;
                }
            }

            .date {

                display: flex;
                align-items: center;
                padding: 0.25rem 0.5rem;
                font-size: .75rem;
                color: $black;
                text-transform: uppercase;

                i {
                    font-size: 1rem;
                    margin-right: .5rem;
                }
            }
        }
    }
}