.account {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .user-in {
        display: flex;

        .welcome {}

        .logout {
            margin-left: auto;
        }
    }

    .user-out {
        .tab-triggers {
            display: flex;
            justify-content: center;
            margin-bottom: 3rem;

            @include media-breakpoint-down(lg) {
                margin-bottom: 2rem;
            }

            .tab-item {
                text-transform: uppercase;
                border-right: 1px solid rgba($border, .2);
                padding: 0 2rem;

                .tab-link {
                    &.active {
                        color: $turq;
                    }
                }

                &:last-child {
                    border-right: 0;
                }
            }
        }
    }
}

.modal.account-modal {

    .modal-dialog {
        max-height: calc(100vh - 10%);

        .modal-header {
            position: relative;
        }

        .btn-close {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            color: white;
            background: none;
            font-size: 1.5rem;
            display: flex;
            height: 2rem;
            width: 2rem;
            padding: 0;
            align-items: center;
            justify-content: center;
            background: $color;
            opacity: 1;
            margin: 0;

            &:hover {
                background: $turq;
            }
        }

        .modal-content {

            .modal-body {
                padding: 1rem;

                form {
                    margin: 2rem 0;

                    .form-control {
                        border-color: rgba($border, 1);
                        border-radius: 0;
                    }

                    .errors {
                        color: red;

                        .error {
                            display: inline-block;
                        }
                    }

                    .form-sender {}
                }

            }
        }

    }
}

.ur-frontend-form {
    border: 0;
    padding: 0;
    font-family: $font;

    .ur-form-row {

        gap: 2rem;

        .ur-form-grid {
            padding: 0 !important;

            .form-row {
                margin-bottom: 1rem !important
            }
        }
    }

    .ur-form-row:first-child {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba($border, .2);
    }

    .ur-label,
    .ur-frontend-form .ur-form-row .ur-form-grid legend.ur-label,
    .ur-frontend-form .ur-form-row .ur-form-grid label.ur-label {
        @extend .form-label;
        font-size: 1rem !important;
        margin: 0 0 .5rem !important;
        color: #212529;
        font-weight: 400 !important;
        line-height: 1.35 !important;
    }

    .input-text.ur-frontend-field {
        @extend .form-control;
        border-color: rgba($border, 1);
        border-radius: 0;
        padding: 0.375rem 0.75rem !important;
    }

    .select.ur-frontend-field {
        @extend .form-select;
        border-color: rgba($border, 1);
        border-radius: 0;
        padding: 0.375rem 0.75rem !important;
    }

    .ur-button-container {
        margin-top: 1rem;
        padding: 0;

        .ur-submit-button {
            @extend .btn;
            margin: 0;
        }
    }

    .user-registration-message,
    .user-registration-error,
    .user-registration-info {
        margin: 0;
        padding: 0;
        background: none !important;
        border-top: 0;
    }

    .user-registration-error {
        background: none !important;
        color: #ff030b;
    }

    .user-registration-message::before,
    .user-registration-error::before,
    .user-registration-info::before {
        display: none;
    }
}