.dots-pagination {
    position: absolute;
    bottom: 0 !important;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    margin: 0 auto;
    padding: 1rem;

    .swiper-pagination-bullet {
        width: .875rem;
        height: .875rem;
        display: inline-block;
        background: transparent;
        opacity: 1;
        border: 2px solid $white;
        margin: 0 0 0 .5rem !important;
        // border-radius: 0;

        &.swiper-pagination-bullet-active {
            background: $white;
        }
    }

}