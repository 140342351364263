.homepage-slider {
	position: relative;

	.homepage-slides {
		// max-width: 120rem;
		display: flex;
		margin: 0 auto;
	}

	.homepage-slide {
		position: relative;
		background: $color;
		height: auto;

		@include media-breakpoint-up(xxl) {
			height: calc(100vh - 3.8125rem);
		}

		.cover {
			display: flex;
			align-items: center;
			justify-content: center;
			@include media-breakpoint-up(xxl) {
				height: 100%;
			}

			picture,
			img {
				max-width: 100%;

				@include media-breakpoint-up(xxl) {
					max-width: none;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}

		.content {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			display: flex;
			z-index: 2;
			padding: 3rem;
			background: rgba($black, 0.125);

			@include media-breakpoint-down(lg) {
				padding: 2rem;
			}

			@include media-breakpoint-down(md) {
				position: relative;
				padding: 1rem;
				background: $color;
				height: auto;
			}

			.wrapper {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: start;
				justify-content: end;
				color: $white;
				font-family: $font;

				@include media-breakpoint-down(md) {
					align-items: center;
					justify-content: center;
					margin: 0 auto;
					text-align: center;
				}

				.title {
					@extend .typo-h;
					margin-bottom: 0.25rem;
					font-size: 3rem;

					@include media-breakpoint-down(lg) {
						font-size: 2rem;
					}

					@include media-breakpoint-down(md) {
						font-size: 1.75rem;
					}

					@include media-breakpoint-down(sm) {
						font-size: 1.625rem;
					}
				}

				.text {
					@extend .typo-r;
					display: block;
				}

				.buttons {
					margin-top: 0.75rem;
					display: flex;
					align-items: start;
					gap: 1rem;
				}
			}
		}

		&:not(.swiper-slide-active) {
			.content {
				.title,
				.text,
				.buttons {
					opacity: 0;
				}
			}
		}

		&.swiper-slide-active {
			.content {
				.title {
					opacity: 0;
					animation: 0.5s 1s animateslide;
					animation-fill-mode: forwards;
				}

				.text {
					opacity: 0;
					animation: 0.5s 1.5s animateslide;
					animation-fill-mode: forwards;
				}

				.buttons {
					opacity: 0;
					animation: 0.5s 2s animateslide;
					animation-fill-mode: forwards;
				}
			}
		}

		@keyframes animateslide {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}
	}

	.slides-controls {
		position: absolute;
		top: 0;
		display: flex;
		width: 3rem;
		justify-content: center;
		height: 100%;
		right: 0;
		z-index: 1;

		@include media-breakpoint-down(lg) {
			width: 2rem;
		}

		@include media-breakpoint-down(md) {
			width: 100%;
			height: 2rem;
		}

		.slides-pagination {
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 0;
			gap: 1.5rem;

			@include media-breakpoint-down(md) {
				flex-direction: row;
				justify-content: end;
				gap: 1rem;
				padding: 1rem;
			}

			.bullet {
				width: 0.875rem;
				height: 0.875rem;
				display: block;
				border-radius: 50%;
				background: $white;
				opacity: 1;

				cursor: pointer;
				transform: scale(0.75);
				transition: 0.3s ease-in-out;

				&:hover {
					transform: scale(0.875);
				}
				&.active {
					transform: scale(1);
				}
			}
		}
	}
}

.modal.video-modal {
	.modal-dialog {
		max-height: calc(100vh - 10%);

		.btn-close {
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
			color: white;
			background: none;
			font-size: 1.5rem;
			display: flex;
			height: 2rem;
			width: 2rem;
			padding: 0;
			align-items: center;
			justify-content: center;
			background: $color;
			opacity: 1;

			&:hover {
				background: $turq;
			}
		}

		.modal-content {
			background: $color;
			// padding: 1rem;
		}

		.modal-body {
			padding: 0;

			.plyr--video {
				max-height: 100%;
			}
		}
	}
}
