.block-files {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .intro {
        padding-bottom: 1rem;
        text-align: center;

        .title {
            @extend .typo-l;
            margin: 0 0 1rem;
            color: $color;
            text-transform: uppercase;
        }

    }

    .files {
        .file {
            display: flex;
            background: $light;
            padding: 1rem;
            border-bottom: 1px solid $white;
            align-items: center;
            text-decoration: none;

            i {
                margin-left: auto;
            }
        }
    }
}