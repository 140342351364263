.designer-header {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .content {
        text-align: left;
        z-index: 1;

        .title {
            @extend .typo-h;
            color: $color;
            margin-bottom: 2rem;
        }

        .intro {
            @extend .typo-r;
            font-weight: bold;
            margin-bottom: 2rem;
        }

        .description {
            @extend .typo-r;
            margin-bottom: 2rem;
        }

        .link {
            @extend .typo-r;
            display: flex;
            justify-content: end;

            a {
                text-decoration: none;

                i {
                    margin-left: .25rem;
                }

                &:hover {
                    color: $turq;
                }
            }
        }
    }

    .media {

        @include media-breakpoint-down(md) {
            margin-bottom: 2rem;
        }
    }
}