.block-quote {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .quote {
        border-left: 1px solid $color;   
        padding: 1rem 0 1rem 2.5rem; 
        display: block;

        @include media-breakpoint-down(md) {
            padding-left: 1.5rem;
        }

        .text {
            @extend .typo-r;
            font-style: italic;
        }

        .cite {
            text-align: left;
            font-size: 1.125rem;
            line-height: 1.5;
            margin-top: .875rem;
            color: $color;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}