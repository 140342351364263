.page-header {
    padding: 4rem 0;
    text-align: center;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
        text-align: left;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .title {
        margin: 0 0 1.5rem;

        @include media-breakpoint-down(md) {
            margin: 0 0 1rem;
        }

        @extend .typo-h;
        margin-top: 0;
        color: $color;
        text-transform: uppercase;
    }

    .subtitle {

        @extend .typo-l;

        margin: 0 0 1rem;

        @include media-breakpoint-down(md) {
            margin: 0 0 1rem;
        }
    }

    .description {
        @extend .typo-r;
    }

    .icon {
        margin: 1.5rem 0;

        @include media-breakpoint-down(md) {
            margin: 1rem 0;
        }

        img {
            width: 6rem;
        }

    }

    .cover {
        margin: 1.5rem 0 0;

        @include media-breakpoint-down(md) {
            margin: 1rem 0 0;
        }

        display: flex;
        align-items: center;
        justify-content: center;

        picture,
        img {
            max-width: 100%;
        }
    }

    // & + .with-bg {
    //     margin-top: -4rem;

    //     @include media-breakpoint-down(lg) {
    //         margin-top: -3rem;
    //     }

    //     @include media-breakpoint-down(md) {
    //         margin-top: -2rem;
    //     }
    // }
}