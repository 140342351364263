/* SASS Variables */
$black: #000000;
$white: #FFFFFF;
$light: #F7F7F7;
$border: #707070;
$text: #333333;
$color: #12253a;
$turq: #3E8894;
$turqalt: #274853;
$gradient: radial-gradient(linear, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
$font: 'Azzurra Sans', sans-serif;
$font-icons: 'Font Awesome 6 Pro';
$font-brands: 'Font Awesome 6 Brands';

@function text-contrast($color) {
    @if (lightness($color) > 50) {
      @return $text;
    } @else {
      @return $white;
    }
  }

/* CSS Variables */
:root {
    --font: 'Azzurra Sans', sans-serif;
    --font-icons: 'Font Awesome 6 Pro';
    --font-brands: 'Font Awesome 6 Brands';
    --color: #12253a;
    --plyr-color-main: #12253a;
    --fa-primary-color: #12253a;
    --fa-secondary-color: #ededed;
}

/* Font-size reference for rem unit */
html {
    font-size: 16px;
    overflow-x: hidden;
    scroll-behavior: smooth;

    @include media-breakpoint-down(md) {
        overflow-x: hidden;
    }
}

/* reset outlines on focus and fields */
*:focus,
input:required {
    outline: 0 !important;
    box-shadow: none !important;
}

/* Body base styles */
body {
    position: relative;
    color: $dark;
    font-family: $font;
    font-weight: 400;
    line-height: 1.35;
    vertical-align: baseline;
    text-size-adjust: none;
    -webkit-text-size-adjust: none !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 

    &.modal-open {
        padding-right: 0 !important;
    }
}

/* Picture tag fix */
picture {
    display: block;
}

p {
}
/* reset last paragraph margin */
*>p:last-child {
    margin-bottom: 0;
}

/* General links */
a {
    color: inherit;
    // transition: .25s ease-in-out;

    &:hover {
        color: $turq;
    }
}

/* Image zoom effect */
.zoom-fx {
    display: block;
    width: 100%;
    overflow: hidden;

    picture,
    img {
        position: relative;
        transition: transform .5s ease-in-out;

        &:hover {
            transform: scale(1.05);
            transition: transform .5s ease-in-out;
        }
    }
}

/* Image zoom function*/

.zoom {
    position: relative;

    .zoom-panel {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: $white;
        pointer-events: none;
        opacity: 0;
    }

    &:hover {
        cursor: move;

        .zoom-panel {
            opacity: 1;
        }
    }
}

/* BGs */
.bg-color {
    background-color: $color;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none;
    animation: none;
  }
}

.gy-fix {
    --bs-gutter-y: 2rem;
}