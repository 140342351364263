.blog-grid {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .blog-masonry {
        display: flex;
        gap: 1rem;

        .masonry-col {
            width: 25%;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @include media-breakpoint-down(xxl) {
                width: 33.33%;

                &:nth-child(4) {
                    display: none;
                }
            }

            @include media-breakpoint-down(lg) {
                width: 50%;

                &:nth-child(3) {
                    display: none;
                }
            }

            @include media-breakpoint-down(md) {
                width: 100%;

                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}

.news-tease {
    // margin-bottom: 2rem;

    .wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        .image-box {
            position: relative;
            background: rgba($color, .5);

            .image {
                display: block;
                transition: .25s ease-in-out;

                picture,
                img {
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .content {
            position: absolute;
            bottom: 0;
            display: flex;
            flex-direction: column;
            padding: 1rem 2rem;

            .title {
                @extend .typo-r;
                color: $white;
                text-decoration: none;
                display: block;
                margin-bottom: 1rem;
                text-transform: uppercase;
                font-weight: bold;
                text-shadow: 0 0 .125rem rgba($dark, 1);
            }

            .meta {
                display: flex;
                align-items: center;
                gap: 1rem;

                .category {
                    background: $turq;
                    color: $white;
                    padding: 0.25rem 0.5rem;
                    display: inline-block;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-size: 0.75rem;

                    &:hover {
                        background: $turqalt;
                        color: $white;
                    }
                    display: none;
                }

                .date {
                    padding: 0.25rem 0;
                    font-size: 0.75rem;
                    color: $white;
                    text-transform: uppercase;
                    margin-top: auto;
                    text-shadow: 0 0 .125rem rgba($dark, 1);
                }
            }
        }
    }

    &:hover {
        .image-box {

            .image {
                mix-blend-mode: none;
                // filter: grayscale(1);
            }
        }
    }
}

.post-share {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    display: flex;

    @include media-breakpoint-down(md) {
        justify-content: flex-start;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        text-decoration: none;
        font-size: 1rem;
        cursor: pointer;

        &:hover {
            color: $turq;
        }
    }
}

.related-posts {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }


    .intro {
        padding-bottom: 1rem;
        text-align: center;

        .title {
            @extend .typo-l;
            margin: 0 0 1rem;
            color: $color;
            text-transform: uppercase;
        }

    }

    .related-masonry {
        display: flex;
        gap: 1rem;

        .masonry-col {
            width: 25%;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @include media-breakpoint-down(xxl) {
                width: 33.33%;

                &:nth-child(4) {
                    display: none;
                }
            }

            @include media-breakpoint-down(lg) {
                width: 50%;

                &:nth-child(3) {
                    display: none;
                }
            }

            @include media-breakpoint-down(md) {
                width: 100%;

                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}