// Griglia tipologie
.type-grid {
	padding: 4rem 0;

	@include media-breakpoint-down(lg) {
		padding: 3rem 0;
	}

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	.type-item {
		.wrapper {
			display: flex;
			position: relative;
			height: 100%;
			flex-direction: column;

			.image-box {
				position: relative;
				background: rgba($color, 0.5);

				.image {
					display: block;

					picture,
					img {
						width: 100%;
						object-fit: contain;
					}
				}
			}

			.content {
				display: flex;
				position: absolute;
				bottom: 0;
				flex-direction: column;
				padding: 1rem 2rem;
				text-shadow: 0 0 0.125rem rgba($dark, 1);
				gap: 1rem;

				.title {
					@extend .typo-r;
					display: block;
					color: $white;
					text-decoration: none;
					text-transform: uppercase;
					font-weight: bold;
				}

				.meta {
					display: flex;
					align-items: center;
					gap: 1rem;

					.category {
						display: inline-block;
						background: $turq;
						color: $white;
						padding: 0.25rem 0.5rem;
						text-transform: uppercase;
						text-decoration: none;
						font-size: 0.75rem;

						&:hover {
							background: $turqalt;
							color: $white;
						}
					}

					.date {
						padding: 0.25rem 0.5rem;
						font-size: 0.75rem;
						color: $white;
						text-transform: uppercase;
						margin-top: auto;
					}
				}
			}
		}

		// &:hover {
		// .image-box {

		// .image {
		// mix-blend-mode: overlay;
		// filter: grayscale(1);
		// }
		// }
		// }
	}
}

// Testata tipologie
.type-header {
	padding: 4rem 0;

	@include media-breakpoint-down(lg) {
		padding: 3rem 0;
	}

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	.title {
		@extend .typo-h;

		margin: 0 0 1.5rem;
		color: $color;
		text-transform: uppercase;

		@include media-breakpoint-down(md) {
			margin: 0 0 1rem;
		}
	}

	.subtitle {
		@extend .typo-l;

		margin: 0 0 1rem;

		@include media-breakpoint-down(md) {
			margin: 0 0 1rem;
		}
	}

	.description {
		@extend .typo-r;
	}

	.techs {
		display: inline-flex;
		// margin-bottom: 1.375rem;
		flex-direction: column;
		gap: 1rem;
		align-self: end;
		justify-self: end;
		margin-top: 2rem;

		@include media-breakpoint-down(lg) {
			align-self: start;
			justify-self: start;
		}

		.tech-grid {
			display: inline-grid;
			grid-template-columns: repeat(3, auto);
			gap: 1rem;
			width: auto;

			.tech {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				font-size: 0.75rem;

				.icon {
					width: 1.5rem;
				}
			}
		}
	}
}

// Griglia prodotti
.products-grid {
	background: $light;
	padding: 4rem 0;

	@include media-breakpoint-down(lg) {
		padding: 3rem 0;
	}

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}
}

// Anteprima prodotti
.product-tease {
	.wrapper {
		border: 1px solid rgba($border, 0.2);
		transition: 0.25 ease-in-out;
		height: 100%;
		display: block;
		text-decoration: none;
	}

	.image-box {
		display: flex;
		position: relative;
		overflow: hidden;

		// .cover {}

		.ar {
			position: absolute;
			right: 0;
			top: 0;
			padding: 0.5rem;
			width: 100%;
			background: $color;
			display: flex;
			gap: 0.5rem;
			align-items: center;
			justify-content: center;
			font-size: 3rem;
			color: $white;

			.icon {
				width: 1.25rem;
				height: 1.25rem;
			}

			span {
				font-size: 0.75rem;
				text-transform: uppercase;
				text-align: center;
			}
		}

		.preview {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			// transform: translateY(-100%);
			// transform-origin: 0 0;
			transition: 0.25s ease-in-out;
		}
	}

	.content {
		display: flex;
		padding: 1rem;
		text-align: center;
		flex-direction: column;
		transition: 0.25 ease-in-out;

		.title {
			display: block;
			font-size: 1.25rem;
			text-transform: uppercase;
			text-decoration: none;
			font-weight: bold;
		}

		.type {
			display: block;
			font-weight: normal;
			margin-bottom: 1rem;
			font-size: 1rem;
		}

		.size {
			margin-top: auto;
			font-size: 1.25rem;
			margin-bottom: 0.5rem;
		}

		.techs {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.5rem;

			.icon {
				width: 1.5rem;
			}
		}
	}

	&:hover {
		.wrapper {
			// border-color: $turq;
		}

		.preview {
			opacity: 1;
			// transform: translateY(0);
		}

		.content {
			color: $turq;
			transition: 0.25 ease-in-out;
		}
	}
}

// Testata prodotti
.product-header {
	padding: 4rem 0;

	@include media-breakpoint-down(lg) {
		padding: 3rem 0;
	}

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	.content {
		text-align: left;
		z-index: 1;

		@include media-breakpoint-down(md) {
			display: flex;
			flex-direction: column;
		}

		.title {
			@extend .typo-h;
			color: $color;
			margin-bottom: 0.5rem;

			@include media-breakpoint-down(md) {
				order: -1;
			}
		}

		.subtitle {
			@extend .typo-l;

			@include media-breakpoint-down(md) {
				order: -1;
			}
		}

		.meta {
			margin: 1rem 0 2rem;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
		}

		.description {
			@extend .typo-r;
		}

		.techs {
			margin: 2rem 0;
			display: inline-flex;
			flex-direction: column;
			gap: 1rem;

			.tech-grid {
				display: inline-grid;
				grid-template-columns: repeat(4, auto);
				gap: 1rem;
				width: auto;

				@media (min-width: 768px) and (max-width: 992px) {
					grid-template-columns: repeat(3, auto);
				}

				.tech {
					display: flex;
					flex-direction: column;
					gap: 0.5rem;
					font-size: 0.75rem;

					.icon {
						width: 1.5rem;
					}
				}
			}
		}

		.downloads {
			margin: 2rem 0;

			h5 {
				@extend .typo-r;
				font-weight: normal;
				text-transform: uppercase;
				margin-bottom: 0.5rem;
			}

			.warning {
				margin-bottom: 0.75rem;

				.modal-action {
					display: inline;
					font-weight: bold;
					cursor: pointer;
					text-decoration: underline;
				}

				.user-in {
					display: flex;

					// .welcome {}

					.logout {
						margin-left: auto;
					}
				}
			}

			.links {
				display: grid;
				grid-template-columns: repeat(3, auto);
				gap: 0.5rem;

				@include media-breakpoint-down(xxl) {
					grid-template-columns: repeat(2, auto);

					.techsheet {
						grid-column: 1/3;
					}
				}

				@include media-breakpoint-down(md) {
					grid-template-columns: repeat(3, auto);

					.techsheet {
						grid-column: 1/2;
					}
				}

				@include media-breakpoint-down(sm) {
					grid-template-columns: repeat(1, auto);
				}
			}
		}

		.actions {
			display: flex;
			flex-direction: column;
			gap: 1rem;
		}

		.view-configurator {
			@include media-breakpoint-down(md) {
				order: -1;
				margin-bottom: 2rem;

				.configurator {
					// margin-top: 0;
				}
			}
		}
	}

	.media {
		@include media-breakpoint-down(md) {
			margin-bottom: 2rem;
		}

		.view-render {
			.renders {
				.zoom {
					border: 1px solid rgba($border, 0.2);
				}
			}
		}

		.view-augmented {
			.nav {
				@extend .typo-l;
				text-transform: uppercase;
				border-color: rgba($border, 0.2);

				.nav-link {
					color: $text;
				}
			}

			.embed {
				border: 1px solid rgba($border, 0.2);
				border-top: 0;
			}

			.ar-warn {
				@include media-breakpoint-down(md) {
					display: none;
				}
			}
		}

		.view-gallery {
			overflow: hidden;

			.product-gallery {
				display: flex;
				flex-direction: column;

				.gallery-navigation {
					display: flex;
					align-self: end;
					align-items: center;
					padding: 0.5rem;

					.product-nav {
						cursor: pointer;
					}

					.product-pagination {
						margin: 0 2rem;
						font-size: 0.875rem;
						color: rgba($border, 0.5);

						.swiper-pagination-current {
							font-size: 1.25rem;
							color: $text;
						}
					}
				}
			}
		}
	}
}

// Finiture prodotti
.product-finish {
	.finish {
		padding: 4rem 0;

		@include media-breakpoint-down(lg) {
			padding: 3rem 0;
		}

		@include media-breakpoint-down(md) {
			padding: 2rem 0;
		}

		&:nth-child(odd) {
			background: $light;
		}

		.media {
			.renders {
				// border: 1px solid rgba($border, .2);

				.zoom {
					display: none;
					border: 1px solid rgba($border, 0.2);

					&.active {
						display: block;
					}
				}
			}
		}

		.content {
			align-self: center;

			@include media-breakpoint-down(lg) {
				align-self: start¸;
			}

			.title {
				@extend .typo-f;
				margin-bottom: 1rem;
			}

			.swatches {
				display: grid;
				gap: 1rem;
				grid-template-columns: repeat(4, 1fr);

				.color {
					margin-bottom: 0.25rem;

					&[data-color] {
						cursor: pointer;
					}
				}

				@include media-breakpoint-down(md) {
					grid-template-columns: repeat(3, 1fr);
				}

				@include media-breakpoint-down(sm) {
					grid-template-columns: repeat(4, 1fr);
				}
			}
		}

		&.no-preview {
			.swatches {
				grid-template-columns: repeat(6, 1fr);

				@include media-breakpoint-down(md) {
					grid-template-columns: repeat(4, 1fr);
				}

				.color {
					&[data-color] {
						cursor: auto;
					}
				}
			}
		}
	}
}

// Prodotti correlati
.product-related {
	padding: 4rem 0;
	overflow: hidden;
	max-width: 100vw;

	@include media-breakpoint-down(lg) {
		padding: 3rem 0;
	}

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	.intro {
		.title {
			@extend .typo-f;
			font-weight: normal;
			margin-bottom: 2rem;
		}

		position: relative;
		text-align: center;
		padding: 0 3rem;

		@include media-breakpoint-down(lg) {
			text-align: left;
			padding: 0 3rem 0 0;
		}

		.related-navigation {
			display: flex;
			position: absolute;
			top: 0;
			right: 0;
			gap: 0.5rem;

			.related-nav {
				cursor: pointer;

				&:hover {
					color: $turq;
				}
			}
		}
	}

	.related-slider {
		position: relative;
		display: flex;
		flex-direction: column;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			height: 100%;
			width: 100%;
			transform: scaleX(-1);
			transform-origin: 0 100%;
			background: $white;
			z-index: 2;
		}

		.related-slide {
			height: auto;

			.product-tease {
				width: 100% !important;
				height: 100% !important;
			}
		}

		.related-nav.swiper-button-disabled {
			opacity: 0.25;
		}
	}

	.product-finish[data-counter="even"] + & {
		background: $light;

		.related-slider {
			&::before {
				background: $light;
			}
		}
	}
}

// Focus collezione
.product-collection {
	padding: 4rem 0;
	background-color: var(--bg);

	@include media-breakpoint-down(lg) {
		padding: 3rem 0;
	}

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	&.contrast {
		color: $white !important;
	}

	.content {
		.title {
			margin-bottom: 2rem;

			h3 {
				@extend .typo-h;
				font-weight: normal;
			}
		}

		.description {
			@extend .typo-r;
		}

		.link {
			margin-top: 2rem;

			.btn {
				color: currentColor;
				border-color: currentColor;

				&:hover {
					border-color: $turq;
					background: $turq;
					color: $white;
				}
			}
		}
	}
}
