@media print {

    header {

        #main-menu {
            display: none;
        }

        .navbar-brand {
            display: block;
        }
    }

    @page {
        size: A4;
    }
}