.block-two-columns {
    padding: 4rem 0;
    background: var(--bg);

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .content {
        padding: 3rem;
        display: grid;

        @include media-breakpoint-down(lg) {
            padding: 2rem 1rem;
        }

        &.contrast {
            color: $white !important;
        }

        .title {
            @extend .typo-h;
            margin: 0 0 1.5rem;

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }
        }

        .text {
            @extend .typo-r;
        }

        .link {
            margin-top: 2rem;

            @include media-breakpoint-down(md) {
                margin-top: 1rem;
            }
        }
    }

    .media {
        z-index: 1;

        .image-wrapper {
            display: block;
            overflow: hidden;
        }

        iframe {
            width: 100%;
            max-width: 100%;
        }

        &.media-contain {
            .image-wrapper {

                picture,
                img {
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        &.media-cover,
        &.forced-cover {
            align-self: stretch;

            .image-wrapper {
                position: relative;
                height: 100%;

                picture,
                img {
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    @include media-breakpoint-down(lg) {
                        position: relative;
                    }
                }
            }

            .plyr {
                height: 100%;
            }
        }

    }

    &.full-width {
        padding: 0;

        @include media-breakpoint-up(lg) {
            .content-wrapper {
                max-width: 38rem;
                justify-self: center;
            }
        }

        .media {
            padding: 0;
        }

    }

    &.media-right {
        .content {
            order: 1;

            @include media-breakpoint-down(lg) {}
        }

        .media,
        .video {
            order: 2;
        }
    }

    &.media-left {
        .media {
            order: 1;

            &.media-break {

                .image-wrapper {
                    justify-content: end;
                }
            }
        }

        .content {
            order: 2;
        }
    }

    &.boxed {
        .content {
            padding: 0 3rem;

            @include media-breakpoint-down(lg) {
                padding: 0 1rem 2rem;
            }
        }
    }

    @include media-breakpoint-down(lg) {

        &.media-right,
        &.media-left {
            .media {
                order: 2;
            }
        }

        .content {
            order: 1;
        }
    }
}