// Griglia tipologie
.homepage-products {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .homepage-item {

        .wrapper {
            display: flex;
            position: relative;
            height: 100%;
            flex-direction: column;

            .image-box {
                position: relative;
                background: rgba($color, 0.5);

                .image {
                    display: block;

                    picture,
                    img {
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }

            .content {
                display: flex;
                position: relative;
                bottom: 0;
                flex-direction: column;
                padding: 1rem;
                gap: 1rem;
                background: $color;
                color: $white;
                text-align: center;
                height: 100%;

                .title {
                    @extend .typo-r;
                    display: block;
                    color: $white;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: bold;
                }

                .description {
                    display: block;
                    color: $white;
                    font-size: 1rem;
                    font-weight: normal;
                }
            }
        }

        &:hover {
            text-decoration: none;
        }
    }
}