.block-free {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    @extend .typo-r;

    :first-child {
        margin-top: 0;
    }

    &.bg-light {
        background: $light;
    }

    &.bg-color {
        background: $color;
        color: $white;
    }
}