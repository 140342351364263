.block-accordion {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }
    
    .accordion-item {

        border: 0;
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }

        .wrapper {
            .trigger {
                background: $color;

                .title {
                    padding: .5rem 2rem;
                    color: $white;
                    display: flex;
                    align-items: center;
                    -webkit-appearance: none;

                    h4 {
                        @extend .typo-h;
                        font-size: 1.5rem;
                        font-weight: bold;
                        margin: 0;
                        text-transform: uppercase;
                    }

                    i {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.75rem;
                        line-height: 1.25;
                        margin-left: auto;
                        transition: .15s ease-in-out;
                    }

                    &:hover {
                        color: $white;
                    }

                    &:not(.collapsed) {
                        color: $white;

                        i {
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            .content {
                padding: 1.5rem;
                border: 1px solid rgba($border, .2);

                .text {
                    @extend .typo-r;
                }
            }
        }

    }

}